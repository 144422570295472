.form-wrapper {
    max-width: 466px;
    padding: 80px 20px;
    min-height: 710px;
    display: block;
    margin: 0 auto;
    text-align: center;
    padding-top: 26px;
}
.form__header {
    margin-bottom: 14px;
}
.form__description {
    margin-bottom: 20px;
    line-height: 20px;
    padding: 0 0px;
}

.form__description br {
    display: none;
}
.select {
    position: relative;
    display: flex;
    align-items: center;
    text-align: left;
    width: 100%;
    padding-left: 12px;
    height: 48px;
    border: 1px solid #EBECF2;
    border-radius: 8px;
    margin-bottom: 16px;
    transition: .3s border-color;
    background-color: #F7F8FD;
    transition: .3s border-color;
}
.select__img {
    height: 24px;
    width: 24px;
    border-radius: 20px;
    
}
.form__input {
    width: 100%;
    border: none;
    background-color: #F7F8FD;
    padding: 14px 20px 14px 56px;
    border-radius: 8px;
}
.form__input_login {
    padding-left: 18px;
}

.form__input_error {
    border-color: var(--red_insta);
}

.btn {
    padding: 15px;
    text-transform: uppercase;
    border-radius: 8px;
    font-weight: 700;
    width: 100%;
    color: var(--main_insta);
    background-color: var(--grey_insta);
    transition: .3s background-color, .3s color;
    text-align: center;
}

.btn_purple {
    color: black;
    background: var(--main_insta);
}
.form__btn {
    max-width: 160px;
    display: inline-block;
    padding: 12px 0;
    margin-top: 32px;
}
.spinner {
    height: 24px;
    width: 24px;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
}

.spinner--2 {
    border: 2px solid rgba(106, 2, 191, 0);
    border-top-color: #6A02BF;
    animation: spinner2 600ms linear infinite;
    border: 2px solid rgba(106, 2, 191, 0);
    border-top-color: #6A02BF;
    animation: spinner2 600ms linear infinite;
    display: none;
    margin-top: -22px;
    margin-left: 70px;
}
.sign_in_btn{
    margin-top: 10px;
}
.select__clear {
    display: block;
    margin-right: 10px;
    height: 24px;
    width: 24px;
}
.select__wrapper {
    position: absolute;
    border-radius: 8px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);
    z-index: 99;
    top: 62px;
    left: 0;
    right: 0;
}
.select__list {
    max-height: 200px;
    overflow-y: auto;
    border-radius: 8px;
    margin: 0 -1px;
}
.select__item {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    display: flex;
    align-items: center;
}
.tooltip__arrow {
    display: block;
    width: 50px;
    height: 25px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    bottom: 100%;
}
.select__item-name {
    font-size: 16px;
}

.select__item-text {
    width: 100%;
    font-size: 14px;
}

.select__item-name {
    font-weight: 700;
}

.select__item-desc {
    font-weight: 300;
    font-size: 14px;
}

.select__item-name,
.select__item-desc {
    display: block;
}

.select__item-img {
    border-radius: 100%;
    margin-right: 15px;
    width: 32px;
    height: 32px;
}
.tooltip__arrow::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    background: white;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    left: 50%;
    box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.2);
}
.select__arrow::after {
    top: 100%;
}
.form__error {
    color: var(--red);
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    display: block;
}
.earn-money_section {
    margin-bottom: 30px;
    background: black;
    position: unset;
    padding: 26px 22px 32px 22px;
}
.rate-content__item {
    position: relative;
    border-radius: 8px;
    flex-shrink: 0;
    padding: 22px 21px 18px 21px;
    margin-left: auto;
    background: black;
    width: calc(100vw - 44px);
        margin-right: 44px;
        border: none;
        padding: 0;
}
.photo_task_container {
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    position: relative;
    flex-shrink: 0;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 24px;
    width: calc(100vw - 104px);
    background: black;
    border: 1px solid black;
    box-sizing: border-box;
    border-radius: 8px;
}

.arrows__item {
    height: 46px;
    width: 61px;
    background-color: #F7F8FD;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    padding: 0;
    transition: .4s background-image;
}
.arrows__item_next {
    border-radius: 7px 0px 0px 7px;
}
.arrow_item_follower {
    position: absolute;
    top: calc(50% - 20px);
    right: -46px;
    background-color: black;
    width: 36px;
    display: block;
 }
 .appeal_text {
    margin-bottom: 0px;
    font-size: 12px;
    line-height: 20px;
    color: white;
    text-align: center;
    font-weight: 800;
    width: 110px;
    width: 108px;
    padding-top: 4px;
    height: 28px;
    border-radius: 40px;
    margin-left: auto;
    margin-right: auto;
    z-index: 999;
}
.appeal_text_bg {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    bottom: 12px;
    left: calc(50% - 54px);
    z-index: 999;
}


.rate-content__actions {
    margin-top: 15px;
    display: block;
    align-items: center;
    width: calc(100vw - 44px);
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
}
.btn-follow {
    padding: 16px;
    text-transform: uppercase;
    border-radius: 8px;
    font-weight: 700;
    width: 100%;
    color: black;
    background-color: white;
    text-align: center;
    border-right: 4px solid var(--tiktok_red);
    border-left: 4px solid var(--tiktok_blue);
    height: 48px;
    position: relative;
}
.task_desc2{
    font-size: 12px;
    line-height: 16px;
    
    color: #929BA2;
    margin-top: 10px;
    opacity: 0.9;
    text-transform: none;
}
.reward_desc{
    font-size: 12px;
    line-height: 16px;
    /* or 133% */

    text-align: center;

    /* blue */

    color: #42EEF5;

    opacity: 0.9;
    margin: 16px 32px 0 32px;
}
.task_desc{
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    text-transform: none;
}
.desc_icon{
    width: 16px;
    height: 16px;
    margin: 0 8px 0 0 ;
}
.plus_coins_plashka{
    position: absolute;
    right: -10px;
    z-index: 99;
    top: -24px;
    background: #ff4081;
    border-radius: 50px;
    font-weight: 900;
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    align-self: center;
    margin: 10px 0;
    padding: 8px 12px;
}
.top_10{
    margin-top: 10px;
}
.btn_task_icon{
    height: 20px;margin-right: 8px; margin-top: -4px;width: auto;border-radius: 0px;
}
.coins_rub_lashka {
    margin-top: 20px;
    margin-bottom: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.subscribe_follow_reward {

    margin-left: auto;
    margin-right: auto;
   
    text-align: center;
        background-color: #6DCEAE;
        border-radius: 4px;
        font-weight: 900;
        font-size: 14px;
        padding: 6px 16px;
        color: white;
        width: auto;
        margin-top: 15px;
        margin-bottom: 10px;
}
.rub_plashka{
    margin-top: 0px;display: flex;
}
.task_text {
    text-align: left;
    margin-bottom: 20px;
}


.icon_64{
    height: 64px; width : 64px;
}
.followers_container_appeal {
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    position: relative;
    flex-shrink: 0;
    font-size: 14px;
    padding: 20px 30px 15px 30px;
    display: flex;
    flex-direction: column;
    background: #F8F9FB;
    border: 1px solid #E3E9F7;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 24px;
    width: calc(100vw - 104px);

}
.accounts__login {
    font-weight: 900;
    margin-bottom: 22px;
    text-align: center;
    margin-bottom: 16px;
}
.block_account {
    text-align: center;
    margin-top: 0px;
    font-size: 12px;
    color: #E1044B;
    opacity: 0.9;
    font-weight: 700;
    margin-bottom: 12px;
    line-height: 16px;
}
.image_task_holder{
    object-fit: cover;max-height: 300px;z-index: 99;
    width: calc(100vw - 104px);
    height: calc(100vw - 104px);
    border-radius: 8px;
}
.accounts__photo {
    border-radius: 100%;
    margin: 0 auto;
    margin-bottom: 12px;
    z-index: 99;
}
.rate-content__video-icon {
    border-radius: 100%;
    width: 36px;
    height: 36px;
    padding: 8px;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 32;
}
.top_0{
    top: 0px;
}
.top_56{
    top: 56px;
    z-index : 999;
}
.placeholder_task {
    position: absolute;
    left: calc(50% - 32px);
    top: calc(50% - 32px);

}
.task_loader_bg{
    height: 360px;
    width: 100%;
}
.loader_task{
    position: absolute;
    top: calc(50% - 18px);
    left: calc(50% - 18px);
    transform: translate(-50%,-50%);
}

.ufo_icon{
    width: 100px;
     left: calc(50% - 50px);
     position: relative;
     margin-bottom: 10px;
     margin-top: calc(50vh - 200px)
 }
 .empty_tasks_container{
     position: relative;
 }
 .empty_tasks_text{
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #98AFB7;
    opacity: 0.9;
 }
 .empty_container_insta{
    position: absolute;
    top: 57px;
    left: 0;
    width: 100%;
    height: calc(100% - 57px);
    z-index: 99;
    background: #000;

}
.no_tasks{
    left: calc(50% - 18px);
    position: relative;
    margin-bottom: 10px;
    margin-top: calc(50vh - 200px);
    margin-bottom: 16px;
}
.btn_update{
    margin-left: 60px;
    margin-right: 60px;
    margin-top: 32px;
    width: auto;
}